import api from '.';

/**
 * [POST] user wallet connect
 * @param {string} client OAuth Provider
 * @param {string} token JWT
 */
export const auth = ({ client, token }) =>
  api
    .post('/auth', {
      client,
      token,
    })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
